import React, { createContext, useContext, useMemo, useState } from "react"

export type DefaultPlatform = "All" | "Android" | "iOS"

export interface DefaultPlatformContent {
  platform: DefaultPlatform
  setPlatform: (platform: DefaultPlatform) => void
}

const DefaultPlatformContext = createContext<DefaultPlatformContent>({
  platform: "All",
  setPlatform: () => {},
})

type Props = {
  children?: React.ReactNode
}

export const DefaultPlatformProvider = ({ children }: Props): JSX.Element => {
  const defaultPlatform = useMemo(() => {
    if (typeof window !== "undefined") {
      const userAgent = window.navigator.userAgent.toLowerCase()
      if (/iphone|ipod/.test(userAgent)) {
        return "iOS"
      } else if (/android/.test(userAgent)) {
        return "Android"
      } else {
        return "All"
      }
    }
    return "All"
  }, [])
  const [platform, setPlatform] = useState<DefaultPlatform>(defaultPlatform)
  return (
    <DefaultPlatformContext.Provider value={{ platform, setPlatform }}>
      {children}
    </DefaultPlatformContext.Provider>
  )
}

export const useDefaultPlatform = () => useContext(DefaultPlatformContext)
