import React from "react"
import "typeface-montserrat"
import "typeface-merriweather"
import type { GatsbyBrowser } from "gatsby"
import { DefaultPlatformProvider } from "./src/hooks/useDefaultPlatform"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <DefaultPlatformProvider>{element}</DefaultPlatformProvider>
}
